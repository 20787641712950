<template>
  <main class="default-container tour-places page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <div class="banner weight-700">
      פרסומות לקטגוריה בסביבה   
    </div>
    <div class="main-content mt-15">
      <h5 class="text-center mb-5">דברים לעשות ברומא</h5>
      <div class="tab-blocks">
        <div class="each-tab pointer active">
          <img class="w-100" src="/assets/img/tour-places/church.png" alt="">
          <div class="tab-title">
            {{ $t("Recommended tourist sites") }}
          </div>
        </div>
        <div class="each-tab pointer">
          <img class="w-100" src="/assets/img/tour-places/church.png" alt="">
          <div class="tab-title">
            {{ $t("Shopping") }}
          </div>
        </div>
        <div class="each-tab pointer">
          <img class="w-100" src="/assets/img/tour-places/church.png" alt="">
          <div class="tab-title">
            {{ $t("Food and restaurants") }}
          </div>
        </div>
        <div class="each-tab pointer">
          <img class="w-100" src="/assets/img/tour-places/museum.png" alt="">
          <div class="tab-title">
            {{ $t("Museums") }}
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div class="each-item">
          <img class="w-100" src="/assets/img/tour-places/church.png" alt="">
            <div class="item-content">
              <p class="weight-700 text-center">
                הקוליסאום
              </p>
              <p class="fs20">
                האמפיתיאטרון הגדול ביותר של האימפריה הרומית הוא חובה לראות ברומא.
              </p>
              <p class="read-more color-blue1 pointer">
                {{ $t("Read more") }}
              </p>
            </div>
        </div>
        <div class="each-item">
          <img class="w-100" src="/assets/img/tour-places/church.png" alt="">
            <div class="item-content">
              <p class="weight-700 text-center">
                הקוליסאום
              </p>
              <p class="fs20">
                האמפיתיאטרון הגדול ביותר של האימפריה הרומית הוא חובה לראות ברומא.
              </p>
              <p class="read-more color-blue1 pointer">
                {{ $t("Read more") }}
              </p>
            </div>
        </div>
        <div class="each-item">
          <img class="w-100" src="/assets/img/tour-places/church.png" alt="">
            <div class="item-content">
              <p class="weight-700 text-center">
                הקוליסאום
              </p>
              <p class="fs20">
                האמפיתיאטרון הגדול ביותר של האימפריה הרומית הוא חובה לראות ברומא.
              </p>
              <p class="read-more color-blue1 pointer">
                {{ $t("Read more") }}
              </p>
            </div>
        </div>
        <div class="each-item">
          <img class="w-100" src="/assets/img/tour-places/church.png" alt="">
            <div class="item-content">
              <p class="weight-700 text-center">
                הקוליסאום
              </p>
              <p class="fs20">
                האמפיתיאטרון הגדול ביותר של האימפריה הרומית הוא חובה לראות ברומא.
              </p>
              <p class="read-more color-blue1 pointer">
                {{ $t("Read more") }}
              </p>
            </div>
        </div>
      </div>  
    </div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TourPlaces",
  components: {
    Loading
  },
  computed: {
    ...mapState({
      loading: (state) => state.deal.loading
    })
  }
};
</script>

<style lang="scss" scoped>
.tour-places {
  .banner {
    background-color: #ffffff;
    text-align: center;
    font-size: 70px;
  }
  .main-content {
    * {
      font-size: 23px;
    }
    .tab-blocks {
      display: flex;
      justify-content: space-between;
      margin: 0 -7px;
      @media (max-width: 768px) {
        justify-content: center;
        flex-wrap: wrap;
      }
      .each-tab {
        display: flex;
        flex-direction: column;
        width: 25%;
        margin: 0 7px;
        border: 1px solid #939393;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #ffffff;
        margin-bottom: 10px;
        @media (max-width: 768px) {
          width: calc(50% - 14px);
        }
        img {
          filter: brightness(80%);
        }
        .tab-title {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 5px 0;
          text-align: center;
          height: 100%;
        }
        &:hover {
          box-shadow: 0px 3px 6px #00000029;
        }
        &.active {
          background-color: #5ACEFF;
          img {
            filter: brightness(100%);
          }
        }

      }
    }
    .tab-content {
      margin: 0 -7px;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      flex-wrap: wrap;
      .each-item {
        display: flex;
        flex-direction: column;
        width: calc(50% - 14px);
        margin: 0 7px 20px;
        border: 1px solid #939393;
        background-color: #ffffff;
        border-radius: 10px;
        @media (max-width: 768px) {
          width: calc(100% - 14px);
        }
        img {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .item-content {
          padding: 5px 30px 10px;
          .read-more {
            text-align: right;
          }
        }
      }
    }
  }
}
.ltr-type {
  .read-more {
    text-align: left;
  }
}
</style>
